[
    {
      "name": "Bulbasaur",
      "image": "https://assets.pokemon.com/assets/cms2/img/pokedex/full/001.png",
      "description": "Bulbasaur can be seen napping in bright sunlight. There is a seed on its back.",
      "link": "https://www.pokemon.com/us/pokedex/bulbasaur"
    },
    {
      "name": "Charmander",
      "image": "https://assets.pokemon.com/assets/cms2/img/pokedex/full/004.png",
      "description": "Charmander's flame on the tip of its tail indicates its life force.",
      "link": "https://www.pokemon.com/us/pokedex/charmander"
    },
    {
      "name": "Squirtle",
      "image": "https://assets.pokemon.com/assets/cms2/img/pokedex/full/007.png",
      "description": "Squirtle's shell helps it minimize damage from attacks.",
      "link": "https://www.pokemon.com/us/pokedex/squirtle"
    },
    {
      "name": "Pikachu",
      "image": "https://assets.pokemon.com/assets/cms2/img/pokedex/full/025.png",
      "description": "Pikachu generates electricity in the electric pouches on its cheeks.",
      "link": "https://www.pokemon.com/us/pokedex/pikachu"
    },
    {
      "name": "Jigglypuff",
      "image": "https://assets.pokemon.com/assets/cms2/img/pokedex/full/039.png",
      "description": "Jigglypuff's songs put people to sleep with a gentle melody.",
      "link": "https://www.pokemon.com/us/pokedex/jigglypuff"
    },
    {
      "name": "Meowth",
      "image": "https://assets.pokemon.com/assets/cms2/img/pokedex/full/052.png",
      "description": "Meowth loves anything that shines. It wanders the streets looking for loose change.",
      "link": "https://www.pokemon.com/us/pokedex/meowth"
    },
    {
      "name": "Psyduck",
      "image": "https://assets.pokemon.com/assets/cms2/img/pokedex/full/054.png",
      "description": "Psyduck suffers frequent headaches, making it unable to think clearly.",
      "link": "https://www.pokemon.com/us/pokedex/psyduck"
    },
    {
      "name": "Machop",
      "image": "https://assets.pokemon.com/assets/cms2/img/pokedex/full/066.png",
      "description": "Machop trains by lifting Gravelers every day.",
      "link": "https://www.pokemon.com/us/pokedex/machop"
    },
    {
      "name": "Geodude",
      "image": "https://assets.pokemon.com/assets/cms2/img/pokedex/full/074.png",
      "description": "Geodude climbs mountain paths using its arms.",
      "link": "https://www.pokemon.com/us/pokedex/geodude"
    },
    {
      "name": "Eevee",
      "image": "https://assets.pokemon.com/assets/cms2/img/pokedex/full/133.png",
      "description": "Eevee has an unstable genetic makeup that suddenly mutates due to the environment.",
      "link": "https://www.pokemon.com/us/pokedex/eevee"
    },
    {
      "name": "Snorlax",
      "image": "https://assets.pokemon.com/assets/cms2/img/pokedex/full/143.png",
      "description": "Snorlax eats nearly 900 pounds of food every day.",
      "link": "https://www.pokemon.com/us/pokedex/snorlax"
    },
    {
      "name": "Mewtwo",
      "image": "https://assets.pokemon.com/assets/cms2/img/pokedex/full/150.png",
      "description": "Mewtwo was created by recombining Mew's genes and is known for its psychic abilities.",
      "link": "https://www.pokemon.com/us/pokedex/mewtwo"
    },
    {
      "name": "Oddish",
      "image": "https://assets.pokemon.com/assets/cms2/img/pokedex/full/043.png",
      "description": "Oddish grows by absorbing moonlight. During the day, it buries itself in the ground.",
      "link": "https://www.pokemon.com/us/pokedex/oddish"
    },
    {
      "name": "Vulpix",
      "image": "https://assets.pokemon.com/assets/cms2/img/pokedex/full/037.png",
      "description": "Vulpix's six beautiful tails gain more warmth as it matures.",
      "link": "https://www.pokemon.com/us/pokedex/vulpix"
    },
    {
      "name": "Growlithe",
      "image": "https://assets.pokemon.com/assets/cms2/img/pokedex/full/058.png",
      "description": "Growlithe has a keen sense of smell and will never forget any scent.",
      "link": "https://www.pokemon.com/us/pokedex/growlithe"
    },
    {
      "name": "Abra",
      "image": "https://assets.pokemon.com/assets/cms2/img/pokedex/full/063.png",
      "description": "Abra sleeps 18 hours a day, using telepathy to sense danger while sleeping.",
      "link": "https://www.pokemon.com/us/pokedex/abra"
    },
    {
      "name": "Gengar",
      "image": "https://assets.pokemon.com/assets/cms2/img/pokedex/full/094.png",
      "description": "Gengar is said to emerge from shadows to steal the lives of those who become lost in the mountains.",
      "link": "https://www.pokemon.com/us/pokedex/gengar"
    },
    {
      "name": "Onix",
      "image": "https://assets.pokemon.com/assets/cms2/img/pokedex/full/095.png",
      "description": "Onix burrows underground at high speeds and can create tunnels as it goes.",
      "link": "https://www.pokemon.com/us/pokedex/onix"
    },
    {
      "name": "Lapras",
      "image": "https://assets.pokemon.com/assets/cms2/img/pokedex/full/131.png",
      "description": "Lapras ferries people across bodies of water with its gentle temperament.",
      "link": "https://www.pokemon.com/us/pokedex/lapras"
    },
    {
      "name": "Dragonite",
      "image": "https://assets.pokemon.com/assets/cms2/img/pokedex/full/149.png",
      "description": "Dragonite can fly faster than the speed of sound and is known for its intelligence.",
      "link": "https://www.pokemon.com/us/pokedex/dragonite"
    },
    {
      "name": "Magikarp",
      "image": "https://assets.pokemon.com/assets/cms2/img/pokedex/full/129.png",
      "description": "Magikarp is virtually useless in battle but evolves into the powerful Gyarados.",
      "link": "https://www.pokemon.com/us/pokedex/magikarp"
    },
    {
      "name": "Rhyhorn",
      "image": "https://assets.pokemon.com/assets/cms2/img/pokedex/full/111.png",
      "description": "Rhyhorn charges in a straight line, smashing everything in its path.",
      "link": "https://www.pokemon.com/us/pokedex/rhyhorn"
    },
    {
      "name": "Jynx",
      "image": "https://assets.pokemon.com/assets/cms2/img/pokedex/full/124.png",
      "description": "Jynx's weird forms of communication sound similar to human speech.",
      "link": "https://www.pokemon.com/us/pokedex/jynx"
    }
  ]